var appen = {
  forms: []
};

appen.init = function() {
  var self = this;

  if ($('.contact-form').length) {
    $('.contact-form').each(function(id, form) {
      self.forms.push(new ContactForm(form));
    });
  }
};

$(document).ready(function() {
  appen.init();
});

const ContactForm = function(form) {
  this.form = $(form);
  this.validate();
};

ContactForm.prototype.validate = function() {
  this.form.validate({
    ignore: [],
    errorElement: 'div',
    errorPlacement: function(error, element) {
      element.closest('.form-group').addClass('has-danger');
      element.addClass('form-control-danger');
      $(element).attr('title', $(error).text());
      $(element).tooltip({
        trigger: 'hover',
        placement: 'top',
        template: '<div class="tooltip" role="tooltip"><h6>' +
          $(element).data('label') +
          '</h6><div class="tooltip-arrow"></div><div class="tooltip-inner"></div></div>'
      });
    },
    unhighlight: function(element) {
      $(element).closest('.form-group').removeClass('has-danger');
      $(element).removeClass('form-control-danger');
      $(element).removeClass('error').addClass('valid');
      $(element).tooltip('dispose');
    },
    rules: {
      name: {
        required: true
      },
      epost: {
        required: true,
        email: true
      }
    },
    messages: {
      name: this.form.find('.contact-form-name').data('message'),
      epost: this.form.find('.contact-form-email').data('message'),
      phone: this.form.find('.contact-form-phone').data('message')
    },
    submitHandler: function(form) {
      ContactForm.prototype.postformdata(form);
    }
  });
};

ContactForm.prototype.postformdata = function(form) {
  var self = this;
  this.form = $(form);
  var messageContainer = this.form.next('.form-contact-status-message');
  var messageFeedback = this.form.prev('.contact-form-feedback');
  var dataString = this.form.serialize();
  dataString = decodeURIComponent(dataString);
  var data = {
    content: dataString,
    vars: ''
  };

  $.ajax({
    type: 'POST',
    url: '/wp-json/api/contact/form/',
    data: data,
    dataType: 'json',
    cache: false,
    beforeSend: function() {
      $('body').addClass('ajax-request');
      self.form.hide();
      $(messageContainer).show();
    },
    success: function(data) {
      $(messageFeedback).html('<h5>' + data.description + '</h5>').show();
      self.form.trigger('reset');
      self.form.hide();
    },
    error: function(data) {
      $(messageFeedback).html('<h5>' + data.description + '</h5>').show();
      self.form.show();
    },
    complete: function() {
      $('body').removeClass('ajax-request');
      $(messageContainer).hide();
      $(messageFeedback).delay(10000).slideUp(1000);
      self.form.delay(10000).slideDown(1000);
    }
  });

  return false;
};
