{
  const navBar = document.getElementById('js-navbar');

  $(window).scroll(() => {
    const scroll = $(window).scrollTop();

    if (scroll > 5) {
      $(navBar).addClass('navbar--filled');
    } else {
      $(navBar).removeClass('navbar--filled');
    }
  });
}

{
  const navBarSide = document.getElementById('navbarSide');
  const overlay = document.getElementById('js-overlay');

  document.getElementById('navbarSideButton').addEventListener('click', () => {
    navBarSide.classList.add('reveal');
    if (overlay.style.display === 'block') {
      overlay.style.display = 'none';
    } else {
      overlay.style.display = 'block';
    }
  });

  overlay.addEventListener('click', () => {
    navBarSide.classList.remove('reveal');
    overlay.style.display = 'none';
  });
}

$('.slider-for').slick({
  slidesToShow: 1,
  slidesToScroll: 1,
  dots: true,
  arrows: true,
  // onAfterChange(slickSlider, i) {
  //   $('.slider-nav .slick-slide').removeClass('slick-active');
  //   $('.slider-nav .slick-slide').eq(i).addClass('slick-active');
  // },
  responsive: [
    {
      breakpoint: 992,
      settings: {
        arrows: false
      }
    }
  ]
});

// $('.slider-nav').slick({
//   slidesToShow: 4,
//   slidesToScroll: 1,
//   asNavFor: '.slider-for',
//   draggable: false,
//   dots: false,
//   focusOnSelect: true,
//   onInit(slickSlider, i) {
//     $('.slider-nav .slick-slide').removeClass('slick-active');
//     $('.slider-nav .slick-slide').eq(i).addClass('slick-active');
//   },
// });

// Set active class to first slide
// $('.slider-nav .slick-slide').eq(0).addClass('slick-active');

{
  const initPhotoSwipeFromDOM = function(gallerySelector) {
    const parseThumbnailElements = function(el) {
      var thumbElements = el.childNodes,
        numNodes = thumbElements.length,
        items = [],
        el,
        childElements,
        thumbnailEl,
        size,
        item;
      for (var i = 0; i < numNodes; i++) {
        el = thumbElements[i];
        // include only element nodes
        if (el.nodeType !== 1) {
          continue;
        }
        childElements = el.children;
        size = el.getAttribute('data-size').split('x');
        // create slide object
        item = {
          src: el.getAttribute('href'),
          w: parseInt(size[0], 10),
          h: parseInt(size[1], 10),
          author: el.getAttribute('data-author')
        };
        item.el = el; // save link to element for getThumbBoundsFn
        if (childElements.length > 0) {
          item.msrc = childElements[0].getAttribute('src'); // thumbnail url
          if (childElements.length > 1) {
            item.title = childElements[1].innerHTML; // caption (contents of figure)
          }
        }
        var mediumSrc = el.getAttribute('data-med');
        if (mediumSrc) {
          size = el.getAttribute('data-med-size').split('x');
          // "medium-sized" image
          item.m = {
            src: mediumSrc,
            w: parseInt(size[0], 10),
            h: parseInt(size[1], 10)
          };
        }
        // original image
        item.o = {
          src: item.src,
          w: item.w,
          h: item.h
        };
        items.push(item);
      }
      return items;
    };
    // find nearest parent element
    var closest = function closest(el, fn) {
      return el && (fn(el) ? el : closest(el.parentNode, fn));
    };
    var onThumbnailsClick = function(e) {
      e = e || window.event;
      e.preventDefault ? e.preventDefault() : (e.returnValue = false);
      var eTarget = e.target || e.srcElement;
      var clickedListItem = closest(eTarget, function(el) {
        return el.tagName === 'A';
      });
      if (!clickedListItem) {
        return;
      }
      var clickedGallery = clickedListItem.parentNode;
      var childNodes = clickedListItem.parentNode.childNodes, numChildNodes = childNodes.length, nodeIndex = 0, index;
      for (var i = 0; i < numChildNodes; i++) {
        if (childNodes[i].nodeType !== 1) {
          continue;
        }
        if (childNodes[i] === clickedListItem) {
          index = nodeIndex;
          break;
        }
        nodeIndex++;
      }
      if (index >= 0) {
        openPhotoSwipe(index, clickedGallery);
      }
      return false;
    };
    var photoswipeParseHash = function() {
      var hash = window.location.hash.substring(1), params = {};
      if (hash.length < 5) {
        // pid=1
        return params;
      }
      var vars = hash.split('&');
      for (var i = 0; i < vars.length; i++) {
        if (!vars[i]) {
          continue;
        }
        var pair = vars[i].split('=');
        if (pair.length < 2) {
          continue;
        }
        params[pair[0]] = pair[1];
      }
      if (params.gid) {
        params.gid = parseInt(params.gid, 10);
      }
      return params;
    };
    var openPhotoSwipe = function(index, galleryElement, disableAnimation, fromURL) {
      var pswpElement = document.querySelectorAll('.pswp')[0], gallery, options, items;
      items = parseThumbnailElements(galleryElement);
      // define options (if needed)
      options = {
        galleryUID: galleryElement.getAttribute('data-pswp-uid'),
        getThumbBoundsFn: function(index) {
          // See Options->getThumbBoundsFn section of docs for more info
          var thumbnail = items[index].el.children[0],
            pageYScroll = window.pageYOffset || document.documentElement.scrollTop,
            rect = thumbnail.getBoundingClientRect();
          return { x: rect.left, y: rect.top + pageYScroll, w: rect.width };
        },
        addCaptionHTMLFn: function(item, captionEl, isFake) {
          if (!item.title) {
            captionEl.children[0].innerText = '';
            return false;
          }
          captionEl.children[0].innerHTML = item.title + '<br/><small>Photo: ' + item.author + '</small>';
          return true;
        }
      };
      if (fromURL) {
        if (options.galleryPIDs) {
          // parse real index when custom PIDs are used
          // http://photoswipe.com/documentation/faq.html#custom-pid-in-url
          for (var j = 0; j < items.length; j++) {
            if (items[j].pid == index) {
              options.index = j;
              break;
            }
          }
        } else {
          options.index = parseInt(index, 10) - 1;
        }
      } else {
        options.index = parseInt(index, 10);
      }
      // exit if index not found
      if (isNaN(options.index)) {
        return;
      }
      var radios = document.getElementsByName('gallery-style');
      for (var i = 0, length = radios.length; i < length; i++) {
        if (radios[i].checked) {
          if (radios[i].id == 'radio-all-controls') {
          } else if (radios[i].id == 'radio-minimal-black') {
            options.mainClass = 'pswp--minimal--dark';
            options.barsSize = { top: 0, bottom: 0 };
            options.captionEl = false;
            options.fullscreenEl = false;
            options.shareEl = false;
            options.bgOpacity = 0.85;
            options.tapToClose = true;
            options.tapToToggleControls = false;
          }
          break;
        }
      }
      if (disableAnimation) {
        options.showAnimationDuration = 0;
      }
      // Pass data to PhotoSwipe and initialize it
      gallery = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, items, options);
      // see: http://photoswipe.com/documentation/responsive-images.html
      var realViewportWidth, useLargeImages = false, firstResize = true, imageSrcWillChange;
      gallery.listen('beforeResize', function() {
        var dpiRatio = window.devicePixelRatio ? window.devicePixelRatio : 1;
        dpiRatio = Math.min(dpiRatio, 2.5);
        realViewportWidth = gallery.viewportSize.x * dpiRatio;
        if (
          realViewportWidth >= 1200 || (!gallery.likelyTouchDevice && realViewportWidth > 800) || screen.width > 1200
        ) {
          if (!useLargeImages) {
            useLargeImages = true;
            imageSrcWillChange = true;
          }
        } else {
          if (useLargeImages) {
            useLargeImages = false;
            imageSrcWillChange = true;
          }
        }
        if (imageSrcWillChange && !firstResize) {
          gallery.invalidateCurrItems();
        }
        if (firstResize) {
          firstResize = false;
        }
        imageSrcWillChange = false;
      });
      gallery.listen('gettingData', function(index, item) {
        if (useLargeImages) {
          item.src = item.o.src;
          item.w = item.o.w;
          item.h = item.o.h;
        } else {
          item.src = item.m.src;
          item.w = item.m.w;
          item.h = item.m.h;
        }
      });
      gallery.init();
    };
    // select all gallery elements
    var galleryElements = document.querySelectorAll(gallerySelector);
    for (var i = 0, l = galleryElements.length; i < l; i++) {
      galleryElements[i].setAttribute('data-pswp-uid', i + 1);
      galleryElements[i].onclick = onThumbnailsClick;
    }
    // Parse URL and open gallery if it contains #&pid=3&gid=1
    var hashData = photoswipeParseHash();
    if (hashData.pid && hashData.gid) {
      openPhotoSwipe(hashData.pid, galleryElements[hashData.gid - 1], true, true);
    }
  };
  initPhotoSwipeFromDOM('.demo-gallery');
}

{
  /*
  *  newap
  *
  *  This function will render a Google Map onto the selected jQuery element
  *
  *  @type  function
  *  @date  8/11/2013
  *  @since 4.3.0
  *
  *  @param   $el (jQuery element)
  *  @return  n/a
  */
  function newMap($el) {
    // marker
    const $markers = $el.find('.marker');

    // constants
    const args = {
      scrollwheel: false,
      draggable: false,
      zoom: 16,
      center: new google.maps.LatLng(0, 0),
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      styles: [
        {
          featureType: 'water',
          elementType: 'geometry',
          stylers: [
            {
              color: '#193341'
            }
          ]
        },
        {
          featureType: 'landscape',
          elementType: 'geometry',
          stylers: [
            {
              color: '#2c5a71'
            }
          ]
        },
        {
          featureType: 'road',
          elementType: 'geometry',
          stylers: [
            {
              color: '#29768a'
            },
            {
              lightness: -37
            }
          ]
        },
        {
          featureType: 'poi',
          elementType: 'geometry',
          stylers: [
            {
              color: '#406d80'
            }
          ]
        },
        {
          featureType: 'transit',
          elementType: 'geometry',
          stylers: [
            {
              color: '#406d80'
            }
          ]
        },
        {
          elementType: 'labels.text.stroke',
          stylers: [
            {
              visibility: 'on'
            },
            {
              color: '#3e606f'
            },
            {
              weight: 2
            },
            {
              gamma: 0.84
            }
          ]
        },
        {
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#ffffff'
            }
          ]
        },
        {
          featureType: 'administrative',
          elementType: 'geometry',
          stylers: [
            {
              weight: 0.6
            },
            {
              color: '#1a3541'
            }
          ]
        },
        {
          elementType: 'labels.icon',
          stylers: [
            {
              visibility: 'off'
            }
          ]
        },
        {
          featureType: 'poi.park',
          elementType: 'geometry',
          stylers: [
            {
              color: '#002D4F'
            }
          ]
        }
      ]
    };

    // create map
    const map = new google.maps.Map($el[0], args);

    // add a markers reference
    map.markers = [];

    // add markers
    $markers.each(function() {
      // eslint-disable-line func-names
      addMarker($(this), map);
    });

    // center map
    centerMap(map);

    google.maps.event.addListener(map, 'click', function enableMapScroll() {
      this.setOptions({
        scrollwheel: true,
        draggable: true
      });
    });

    // return
    return map;
  }

  /*
  *  addMarker
  *
  *  This function will add a marker to the selected Google Map
  *
  *  @type function
  *  @date 8/11/2013
  *  @since 4.3.0
  *
  *  @param $marker (jQuery element)
  *  @param map (Google Map object)
  *  @return n/a
  */
  function addMarker($marker, map) {
    // var
    const latlng = new google.maps.LatLng($marker.attr('data-lat'), $marker.attr('data-lng'));

    // create marker
    const marker = new google.maps.Marker({
      position: latlng,
      map
    });

    // add to array
    map.markers.push(marker);

    // if marker contains HTML, add it to an infoWindow
    if ($marker.html()) {
      // create info window
      const infowindow = new google.maps.InfoWindow({
        content: $marker.html()
      });

      // show info window when marker is clicked
      google.maps.event.addListener(marker, 'click', () => {
        infowindow.open(map, marker);
      });
    }
  }

  /*
  *  centerMap
  *
  *  This function will center the map, showing all markers attached to this map
  *
  *  @type function
  *  @date 8/11/2013
  *  @since 4.3.0
  *
  *  @param map (Google Map object)
  *  @return n/a
  */
  function centerMap(map) {
    // vars
    const bounds = new google.maps.LatLngBounds();

    // loop through all markers and create bounds
    $.each(map.markers, (i, marker) => {
      const latlng = new google.maps.LatLng(marker.position.lat(), marker.position.lng());
      bounds.extend(latlng);
    });

    // only 1 marker?
    if (map.markers.length === 1) {
      // set center of map
      map.setCenter(bounds.getCenter());
      map.setZoom(16);
    } else {
      // fit to bounds
      map.fitBounds(bounds);
    }
  }

  /*
  *  document ready
  *
  *  This function will render each map when the document is ready (page has loaded)
  *
  *  @type function
  *  @date 8/11/2013
  *  @since 5.0.0
  *
  *  @param n/a
  *  @return n/a
  */
  // global var
  let map = null;

  $(document).ready(() => {
    $('#acf-map').each(function() {
      // eslint-disable-line func-names
      // create map
      map = newMap($(this));
    });
  });
}
